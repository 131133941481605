import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Lightbox from "../components/lightbox"
import styled from 'styled-components'
import Footer from "../components/footer"
import Header from "../components/header"

// class gallery extends React.Component {

//   constructor() {
//     super();
//   }


//   render() {
//     return (
//       <div>

//       </div>
//     );
//   }
// }

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  //const { allFile } = data // data.markdownRemark holds our post data
  //console.log({data})
  //const { frontmatter, html } = allFile
  const { mainContent } = data
  const { frontmatter, html } = mainContent.edges[0].node.childMarkdownRemark
  const { galleriesQuery } = data
  const galleryArray = galleriesQuery.edges
  // console.log(galleryArray)

  var galleries = groupBy(galleryArray, 'relativeDirectory')
  function groupBy(array, property) {
    var hash = {};
    for (var i = 0; i < array.length; i++) {
      if (!hash[array[i].node[property]]) hash[array[i].node[property]] = [];
      hash[array[i].node[property]].push(array[i]);
    }
    return hash;
  }
  
  galleries = sortObject(galleries);
  // console.log(galleries)
  function sortObject(o) {
    var sorted = {},
      key, a = [];

    for (key in o) {
      if (o.hasOwnProperty(key)) {
        a.push(key);
      }
    }

    a.sort();
    // debugger
    for (key = 0; key < a.length; key++) {
      sorted[a[key]] = o[a[key]];
    }
    return sorted;
  }

  return (
    <div className="projects-page">
      <Header siteTitle={data.site.siteMetadata.title} />
      <SEO title={frontmatter.title + " Projects"} />
      <div className="container">
        <h1><u>{frontmatter.title}.</u></h1>
        <div className="columns">
          <div className="column">
            <div className="main-content" dangerouslySetInnerHTML={{ __html: html }} />
            <GalleryWrapper>
              {Object.keys(galleries).map(gallery => (
                <Lightbox images={galleries[gallery]}  />
              ))}
            </GalleryWrapper>

            {/* {Object.keys(galleries).map(gallery => (
              <div className="gallery-wrapper"> {
              galleries[gallery].map(image => {
                  return image.node.childImageSharp != null ?
                  <Img
                    alt={image.node.name}
                    key={image.node.childImageSharp.fluid.src}
                    fluid={image.node.childImageSharp.fluid}
                    style={{ margin: '3rem 0' }}
                  />
                  :
                  <h2>{image.node.childMarkdownRemark.frontmatter.title}</h2>
                }
            )}
              </div>
            ))} */}
          </div>
        </div>
      </div>
<Footer />
</div>
  )
}

const GalleryWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: 700px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: 1100px) {
    grid-template-columns: repeat(3, 1fr);
  }

  grid-gap: 15px;
  .gatsby-image-outer-wrapper {
    height: 100%;
  }
`

// const Line = styled.div`
//   border-bottom: 10px solid black;
// `

// const SecondPage = () => (
  
//     <h1>Hi from the second page</h1>
//     <p>Welcome to page 2</p>
    
// )

// export default SecondPage

// Better off building two quieries 
// one for the main markdown
// one that lists the galleries and their markdown files - then figure out how to get them on the page  s
export const pageQuery = graphql`
query projects($project: String!, $glob: String!) {
    site {
          siteMetadata {
            title
          }
        }
  mainContent: allFile(filter: {name: {eq: $project}, relativeDirectory: {eq: $project}, ext: {eq: ".md"}}) {
    edges {
      node {
        childMarkdownRemark {
          frontmatter {
            title
            path
          }
          html
        }
        relativeDirectory
      }
    }
  }
  galleriesQuery: allFile(filter: {relativeDirectory: {glob: $glob}}) {
    edges {
      node {
        childMarkdownRemark {
          frontmatter {
            title
            path
            cover {
              childImageSharp {
                id
                original {
                  src
                }
              }
            }
          }
          html
        }
        childImageSharp {
          id
          original {
            src
          }
          fluid(maxHeight: 800) {
            ...GatsbyImageSharpFluid
          }
          fixed(width: 300, height: 220, cropFocus: CENTER) {
            ...GatsbyImageSharpFixed
          }
        }
        name
        relativeDirectory
      }
    }
  }
}
`