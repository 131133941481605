import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Img from 'gatsby-image'
import next from "../img/next.png"
import close from "../img/close.png"
import previous from "../img/previous.png"

class Lightbox extends Component {

  constructor (props) {
    super(props);
  }
  state = {
    showLightbox: false,
    selectedImage: 0,
    galleryLength: 0
  }

  componentDidMount = () => {
    window.addEventListener('keyup', this.handleKeyUp, false)
  }

  componentWillUnmount = () => {
    window.removeEventListener('keyup', this.handleKeyUp, false)
  }

  handleClick = (e, index) => {
    e.preventDefault()
    this.setState({ showLightbox: !this.state.showLightbox, selectedImage: index })
  }

  closeModal = () => {
    this.setState({ showLightbox: false })
  }

  goBack = () => {
    this.setState({ selectedImage: this.state.selectedImage - 1 })
  }

  goForward = () => {
    this.setState({ selectedImage: this.state.selectedImage + 1 })
  }

  handleKeyUp = e => {
    e.preventDefault()
    const { keyCode } = e
    const length = this.galleryImages(this.props.images).length
    if (this.state.showLightbox) {
      if (keyCode === 37) {
        // Left Arrow Key
        if (this.state.selectedImage > 0) {
          this.setState({ selectedImage: this.state.selectedImage - 1 })
        }
      }
      if (keyCode === 39) {
        // Right Arrow Key
        if (this.state.selectedImage < length - 1) {
          this.setState({ selectedImage: this.state.selectedImage + 1 })
        }
      }
      if (keyCode === 27) {
        // Escape key
        this.setState({ showLightbox: false })
      }
    }
  }
  galleryFront = (images) => {
    //const images = props.images
    var frontImage = [];
    var md;
    var frontId;

    images.forEach(function (img) {
      if (img.node.childMarkdownRemark != null) {
        md = img.node.childMarkdownRemark
        frontId = img.node.childMarkdownRemark.frontmatter.cover.childImageSharp.original.src
      } else {
        if (img.node.childImageSharp.original.src === frontId) {
          frontImage.push(img)
        }
      }
    })

    
    const retImage = frontImage.map((img, i) =>
      <a href={img.node.childImageSharp.fluid.src} onClick={e => this.handleClick(e, i)}>
        <Img fixed={img.node.childImageSharp.fixed} />
      </a>
    )
    return (<GalleryItem>
      {retImage}
      <h3><u>{md.frontmatter.title}</u></h3>
    </GalleryItem>
    )
  }

  galleryImages = (images) => {
    var galleryImages= [];
    images.forEach(function (img) {
      if (img.node.childImageSharp != null) {
        galleryImages.push(img)
      }
    })
    galleryImages.sort(this.compare);
    return galleryImages
  }

  compare = (a, b) => {
    if (a.node.name < b.node.name) {
      return -1;
    }
    if (a.node.name > b.node.name) {
      return 1;
    }
    return 0;
  }



  render() {
    const { images } = this.props
    const { showLightbox, selectedImage } = this.state
    const galleryImages = this.galleryImages(images)
    //galleryImages = this.galleryImages.sort(compare);
    
    // console.log(galleryImages.length)
    // this.setState({ galleryLength: galleryImages.length })

    // console.log(images)
    return (
      <Fragment>
          <Gallery>
            {this.galleryFront(images) }
          </Gallery>

        <LightboxModal visible={showLightbox} onKeyUp={e => this.handleKeyDown(e)}>
          <Close onClick={this.closeModal}></Close>
            <LightboxContent>
            <Img sizes={galleryImages[selectedImage].node.childImageSharp.fluid} imgStyle={{ objectFit: "contain" }} style={{ maxHeight: "90vh", width: "auto" }} />
            
              <Controls>
              <Button className="closeButton" onClick={this.closeModal}><img src={close} /></Button>
                <LeftRight>
                  <Button className="leftButton" onClick={this.goBack} disabled={selectedImage === 0}>
                  <img src={previous} />
                  </Button>
                <Button className="rightButton" onClick={this.goForward} disabled={selectedImage === galleryImages.length - 1}>
                  <img src={next} />
                  </Button>
                </LeftRight>
              </Controls>
            </LightboxContent>
          </LightboxModal>
      </Fragment>
    )
  }
}

// const StyledImg = styled(Img)`
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   //z-index: -1;
//   height: 100%; // or whatever
//   & > img {
//     object-fit: cover !important; // or whatever
//     object-position: 0% 0% !important; // or whatever
//   }
// `

const Gallery = styled.div`
  // display: grid;
  // grid-template-columns: 1fr;
  // @media (min-width: 700px) {
  //   grid-template-columns: 1fr 1fr;
  // }
  // @media (min-width: 900px) {
  //   grid-template-columns: 1fr 1fr 1fr;
  // }

  // @media (min-width: 1100px) {
  //   grid-template-columns: repeat(3, 1fr);
  // }

  // grid-gap: 15px;
  // .gatsby-image-outer-wrapper {
  //   height: 100%;
  // }
`
const Close = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
`

const GalleryItem = styled.div`
  text-align: center
`

const Button = styled.button`
  // background: #fffaf2;
  // border-radius: 3px;
  // border: 1px solid hsl(0, 0%, 21%);
  // padding: 8px;
  // margin: 1rem 0 0 0;
  background: transparent;
  border: none;
  padding: 0;
  height: 60px;
  position: fixed;
  //top: calc(50% - 60px);
  bottom: 5px;
  right: 70px

  img {
    max-height: 40px;
    opacity: .8;
  }

  :hover img {
    opacity: 1;
  }
`

const LightboxModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  z-index:1000;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  opacity: ${props => (props.visible ? '1' : '0')};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`
const LightboxContent = styled.div`
  margin: 15px;
  width: 90vw;
  max-width: 1200px;
  width: 100%;
  max-height: 90vh;
  position: relative;
`

const Controls = styled.div`
  // display: flex;
  // justify-content: space-between;
`

const LeftRight = styled.div`
  // button:first-child {
  //   margin-right: 10px;
  // }
`

Lightbox.propTypes = {
  images: PropTypes.array.isRequired,
  // md: PropTypes.array.isRequired,
  // front: PropTypes.array.isRequired,
}

export default Lightbox
